/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
  font-family: 'Calibri';
  src: url('./fonts/calibri/calibrib.eot');
  src: url('./fonts/calibri/calibrib.eot?#iefix') format('embedded-opentype'),
       url('./fonts/calibri/calibrib.woff2') format('woff2'),
       url('./fonts/calibri/calibrib.woff') format('woff'),
       url('./fonts/calibri/calibrib.ttf') format('truetype'),
       url('./fonts/calibri/calibrib.svg#calibribold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('./fonts/calibri/calibri.eot');
  src: url('./fonts/calibri/calibri.eot?#iefix') format('embedded-opentype'),
       url('./fonts/calibri/calibri.woff2') format('woff2'),
       url('./fonts/calibri/calibri.woff') format('woff'),
       url('./fonts/calibri/calibri.ttf') format('truetype'),
       url('./fonts/calibri/calibri.svg#calibriregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('./fonts/calibri/calibrii.eot');
  src: url('./fonts/calibri/calibrii.eot?#iefix') format('embedded-opentype'),
       url('./fonts/calibri/calibrii.woff2') format('woff2'),
       url('./fonts/calibri/calibrii.woff') format('woff'),
       url('./fonts/calibri/calibrii.ttf') format('truetype'),
       url('./fonts/calibri/calibrii.svg#calibriitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

html,
body {
  /* overflow-x: hidden; */
  /* width: 100%; */
  margin: 0;
  padding: 0;
  font-family: "Calibri", Arial, sans-serif;
}

.sd-root-modern {
  height: auto !important;
}

.sd-root-modern--full-container {
  height: auto !important;
}

.sd-progress {
  height: 12px !important;
}

.sd-progress__bar {
  background-color: #0e89bc !important;
}

.sd-item--checked .sd-item__decorator {
  background: #0e89bc !important;
}

.sd-item__control:focus + .sd-item__decorator {
  box-shadow: 0 0 0 2px #0e89bc !important;
  background: var(
    --sjs-questionpanel-backcolor,
    var(
      --sjs-question-background,
      var(--sjs-general-backcolor, var(--background, #fff))
    )
  ) !important;
}

.sd-radio--checked .sd-radio__control:focus + .sd-radio__decorator:after {
  background-color: #0e89bc !important;
}

.sd-header__text .sd-title {
  color: #0e89bc !important;
}

.sd-btn {
  background: #ffd800 !important;
  color: #000000 !important;
  transition: all 0.3s ease-in-out !important;
}

.sd-btn:hover {
  background: #b29700 !important;
  /* color: #ffffff !important; */
}

.sd-timer__progress {
  stroke: #0e89bc !important;
}

.sd-timer__text-container {
  color: #0e89bc !important;
}

.sd-timer__text--major {
  color: #0e89bc !important;
}

#completed-assessment-back-button {
  background-color: #ffd800;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 40px;
  transition: all 0.3s ease-in-out;
}

#completed-assessment-back-button:hover {
  background-color: #b29700;
}

#completed-assessment-back-button > a {
  text-decoration: none;
  color: black;
}

#completed-assessment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sd-boolean__thumb {
  background-color: #0E89BC !important;
  color: white !important;
}

.sd-boolean.sd-boolean--allowhover:focus-within {
  box-shadow: 0 0 0 2px #0E89BC !important;
}

.sd-title.sd-container-modern__title {
  box-shadow: 0px 2px 0px #0E89BC !important;
}
/* .sd-timer--top {
  top: calc(var(--sd-timer-size) / 144* 90) !important;
} */

/* .sd-timer {
  left: calc(var(--sd-timer-size) / 135 * 800) !important;
  top: calc(var(--sd-timer-size) / 135 * 60) !important;
  position: fixed !important;
} */

.default-font-family {
  font-family: 'Calibri', sans-serif;
}

.sd-root-modern--mobile .sd-body.sd-body--static {
  padding-top: 3rem !important;
}